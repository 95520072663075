.go695645437{
    box-shadow: none !important;
}
.go2225988090{
    overflow-y: auto !important;
}
.rsm-date-title {
    font-size: 20px !important;
}

.appointment-form {
    padding: 20px;
}

@media screen and (max-width:768px){
    .appointment-form {
        padding-right: 5px;
    }
}

.input-group{
    margin-top: 8px;
    margin-bottom: 8px;
}
.input-label {
    color: #000;
    font-weight: 600;
    padding: 10px 0px;
    display: block;
}

.form-control {
    padding: 12px 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}


.arrowButton {
    width: 30px; height: 30px; background-color: #b1a9a9;
    text-align: center; border-radius: 50%;
    margin-right: 10px
}
.arrowButton:hover {
    background-color: #beb8b8;
    cursor: pointer;
    color: #09F;
}

.bank-details-table {
    width: 100%;
    margin: 0 auto;
}
.bank-details-table {
    overflow-x: auto;
}

.bank-details-table, .bank-details-table tr, .bank-details-table th, .bank-details-table td {
    border: 1px solid #ccc;
    border-collapse: collapse;
}

.bank-details-table tr, .bank-details-table th, .bank-details-table td {
    padding: 6px 10px;
}

.text-center {
    text-align: center;
}

.text-primary {
    color: #09F;
}
.text-danger {
    color: rgba(221, 46, 46, 0.774)
}